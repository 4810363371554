import React from 'react'
import './Blog.css'
import post1Image from '../../assets/blog-post-1-image.webp'
import { Link } from 'react-router-dom'

function Post({ image, date, title, subtitle, slug }) {
    return (
        <Link
            to={`/blog/${slug}`}
            className="post-link-img">
            <div className="post">
                <img
                    className="post-image"
                    src={image}
                    alt={title}
                />
                <div className="post-content">
                    <p className="blog-post-date">{date}</p>
                    <h3 className="post-title">{title}</h3>
                    <p className="post-subtitle">{subtitle}</p>
                </div>
            </div>
        </Link>
    )
}

export default function Blog() {
    // Get current date, subtract 2 days (in milliseconds), and format the result
    const twoDaysBefore = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })

    const posts = [
        {
            image: post1Image,
            date: twoDaysBefore,
            title: 'How to Start Renting Out Your Driveway',
            subtitle: 'Learn steps on listing your spot and how much you can make...',
            slug: 'how-to-rent-your-driveway',
        },
    ]

    return (
        <div className="blog-page-container">
            <div className="banner">
                <h2 className="banner-title">Blog Posts</h2>
                <p className="banner-subtitle"> Articles, resources, and guides on renting your spaces for passive income.</p>
            </div>

            <div className="posts-container">
                {posts.map((post, index) => (
                    <Post
                        key={index}
                        image={post.image}
                        date={post.date}
                        title={post.title}
                        subtitle={post.subtitle}
                        slug={post.slug}
                    />
                ))}
            </div>
        </div>
    )
}
