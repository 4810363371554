import React from 'react'
import { Link } from 'react-router-dom'

import './Landing.css'

import RenterDemo from '../../assets/demos/renter_demo.mp4'
import HostDemo from '../../assets/demos/host_demo.mp4'

import DriverIcon1Svg from '../../assets/driver-icon-1.png'
import DriverIcon2Svg from '../../assets/driver-icon-2.png'
import DriverIcon3Svg from '../../assets/driver-icon-3.png'
import HostIcon1Svg from '../../assets/host-icon-1.png'
import HostIcon2Svg from '../../assets/host-icon-2.png'
import HostIcon3Svg from '../../assets/host-icon-3.png'
import AppStoreBtn from '../../assets/app-store-btn.png'
import GooglePlayBtn from '../../assets/google-play-btn.png'

export default function Landing() {
    // Selected tab for demo video
    const [selectedTab, setSelectedTab] = React.useState('Driver')
    // Refs to control video
    const renterVideoRef = React.useRef(null)
    const hostVideoRef = React.useRef(null)

    // Restarts video whenever tab selection changes
    React.useEffect(() => {
        const renterVideo = renterVideoRef.current
        const hostVideo = hostVideoRef.current

        const playVideo = (video) => {
            if (video) {
                video.pause()
                video.currentTime = 0
                video.play().catch((error) => console.error(error))
            }
        }

        if (selectedTab === 'Driver') {
            hostVideo.pause()
            playVideo(renterVideo)
        } else if (selectedTab === 'Host') {
            renterVideo.pause()
            playVideo(hostVideo)
        }

        // Add class for low power mode workaround
        renterVideo.classList.add('inlinevideo')
        hostVideo.classList.add('inlinevideo')

        // Event listener for user interaction to play video
        const handleUserInteraction = () => {
            if (renterVideo.paused) renterVideo.play()
            if (hostVideo.paused) hostVideo.play()
        }

        document.body.addEventListener('click', handleUserInteraction)
        document.body.addEventListener('touchstart', handleUserInteraction)

        // Cleanup event listeners on unmount
        return () => {
            document.body.removeEventListener('click', handleUserInteraction)
            document.body.removeEventListener('touchstart', handleUserInteraction)
        }
    }, [selectedTab])

    return (
        <div className="landing-page-container">
            <div className="section-banner">
                <div className="introduction-container">
                    <h1 className="slogan">
                        We're your Neighborhood <span style={{ color: '#316cf6' }}>Parking</span> Partner.
                    </h1>
                    <h3 className="sub-slogan">Find a parking spot or rent out your driveway.</h3>

                    <h2 className="download-btns">
                        <a
                            href="https://apps.apple.com/us/app/parket-parking-marketplace/id6469010141"
                            target="_blank"
                            rel="noreferrer">
                            <img
                                className="app-store-btn"
                                src={AppStoreBtn}
                                alt="parket-app-store"
                            />
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=org.asphalt.parket"
                            target="_blank"
                            rel="noreferrer">
                            <img
                                className="google-play-btn"
                                src={GooglePlayBtn}
                                alt="parket-google-play"
                            />
                        </a>
                    </h2>
                </div>
                <div className="banner-container">
                    <div className="tab-container">
                        <button
                            className={`tab-button ${selectedTab === 'Driver' ? 'active' : ''}`}
                            onClick={() => setSelectedTab('Driver')}>
                            Driver
                        </button>
                        <button
                            className={`tab-button ${selectedTab === 'Host' ? 'active' : ''}`}
                            onClick={() => setSelectedTab('Host')}>
                            Host
                        </button>
                    </div>
                    <video
                        className="banner-video"
                        ref={renterVideoRef}
                        src={RenterDemo}
                        style={selectedTab !== 'Driver' ? { visibility: 'hidden', position: 'absolute' } : { visibility: 'visible', position: 'relative' }}
                        autoPlay
                        loop
                        preload="auto"
                        muted
                        playsInline>
                        Your browser does not support the video tag.
                    </video>
                    <video
                        className="banner-video"
                        ref={hostVideoRef}
                        src={HostDemo}
                        style={selectedTab !== 'Host' ? { visibility: 'hidden', position: 'absolute' } : { visibility: 'visible', position: 'relative' }}
                        autoPlay
                        loop
                        preload="auto"
                        muted
                        playsInline>
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>

            <div className="renter-container">
                <h2 className="renter-title"> Drivers </h2>
                <p className="renter-desc">Find parking for your daily needs or events—book instantly or schedule ahead.</p>
                <div className="renter-slider-container">
                    <div className="renter-slider">
                        <img
                            className="renter-slider-img"
                            src={DriverIcon1Svg}
                            alt=""
                        />
                        <h3 className="renter-slider-title"> Search </h3>
                        <p className="renter-slider-desc">Filter by time and location to find the perfect spot.</p>
                    </div>
                    <div className="renter-slider">
                        <img
                            className="renter-slider-img"
                            src={DriverIcon2Svg}
                            alt=""
                        />
                        <h3 className="renter-slider-title"> Book </h3>
                        <p className="renter-slider-desc">Pay and confirm your reservation.</p>
                    </div>
                    <div className="renter-slider">
                        <img
                            className="renter-slider-img"
                            src={DriverIcon3Svg}
                            alt=""
                        />
                        <h3 className="renter-slider-title"> Park </h3>
                        <p className="renter-slider-desc">Follow access instructions and park with ease.</p>
                    </div>
                </div>
            </div>
            <div className="divider" />
            <div className="host-container">
                <h2 className="host-title"> Hosts </h2>
                <p className="host-desc">
                    Rent out your underutilized parking space and earn <span style={{ color: '#316cf6' }}>$150-$400 per month.</span>
                </p>
                <div className="host-slider-container">
                    <div className="host-slider">
                        <img
                            className="host-slider-img"
                            src={HostIcon1Svg}
                            alt=""
                        />
                        <h3 className="host-slider-title"> List </h3>
                        <p className="host-slider-desc">Add your location, availabilities, and rates—it's free to list.</p>
                    </div>
                    <div className="host-slider">
                        <img
                            className="host-slider-img"
                            src={HostIcon2Svg}
                            alt=""
                        />
                        <h3 className="host-slider-title"> Scheduling </h3>
                        <p className="host-slider-desc">Wait for drivers—we'll handle the scheduling and payments.</p>
                    </div>
                    <div className="host-slider">
                        <img
                            className="host-slider-img"
                            src={HostIcon3Svg}
                            alt=""
                        />
                        <h3 className="host-slider-title"> Earnings </h3>
                        <p className="host-slider-desc">Track your earnings and manage payouts.</p>
                    </div>
                </div>
            </div>

            <div className="parking-lot-container">
                <div className="parking-lot-details">
                    <div className="parking-lot-texts">
                        <h2 className="parking-lot-title">Own a small parking lot?</h2>
                        <p className="parking-lot-text">Add a new revenue stream or maximize yield from underused car parks and vacant land with Parket.</p>
                        <Link
                            className="parking-lot-learn"
                            to="help">
                            Contact Us
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
