import React from 'react'

const AppRedirect = () => {
    console.log('HELLO')
    React.useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera

        // Check for iOS devices
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            window.location.replace('https://apps.apple.com/us/app/parket-parking-marketplace/id6469010141') // iOS App Store URL
        }
        // Check for Android devices
        else if (/android/i.test(userAgent)) {
            window.location.replace('https://play.google.com/store/apps/details?id=org.asphalt.parket') // Google Play Store URL
        }
        // Fallback (Desktop or unsupported devices)
        else {
            window.location.replace('https://joinparket.com') // Fallback URL
        }
    }, [])

    return <div style={{ color: '#000' }}>Redirecting to the app store...</div>
}

export default AppRedirect
