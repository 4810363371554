import React from 'react'
import './Posts.css'
import post1Image from '../../../assets/blog-post-1-image.webp'
import sarahPalms from '../../../assets/sarah-palms.jpg'

export default function HowToRentYourDriveway() {
    // Get current date, subtract 2 days (in milliseconds), and format the result
    const twoDaysBefore = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })

    return (
        <div className="post-detail-container">
            <h1>How to Rent Out Your Driveway</h1>

            <img
                className="post-detail-image"
                src={post1Image}
                alt="Rent Your Driveway"
            />

            {/* Author Info and Date */}
            <div className="author-container">
                <img
                    className="author-image"
                    src={sarahPalms} // Use your author image source here
                    alt="Author"
                />
                <div className="author-details">
                    <p className="author-name">
                        By <span style={{ textDecorationLine: 'underline' }}>Sarah Palms</span>
                    </p>
                    <p className="post-date">Published {twoDaysBefore}</p>
                </div>
            </div>

            <section className="post-section">
                <p>
                    Renting out your driveway can be a fantastic way to generate passive income, especially if you’re looking for a way to pay off bills or save extra money. If your driveway sits
                    empty, why not transform it into a revenue stream?
                </p>
                <br />
                <p>
                    In crowded cities, where parking is scarce and expensive, offering your driveway to a driver in need can mean a steady flow of cash each month. Imagine turning a patch of concrete
                    into an asset that works for you — no extra effort required once you get started.
                </p>
            </section>

            <section className="post-section">
                <h2>1. Doing it Yourself</h2>
                <p>
                    If you want to rent out your driveway independently, be prepared for a bit of work. It starts with taking several photos of your space — make sure to capture important angles, from
                    the entrance to any surrounding areas. These photos will be the first thing prospective renters see, so they need to make your driveway look accessible.
                </p>
                <br />
                <p>
                    Next, post your listing on platforms like Craigslist, Facebook Marketplace, OfferUp, or even stick up flyers around the neighborhood. Write out your title and description so you
                    copy-paste it across platforms. Afterwards, you'll need to communicate with potential renters, often answering dozens of messages from people asking, "Is this still available?"
                </p>
                <br />
                <p>
                    Don't forget the vetting process. You'll want to ensure the driver is reliable, trustworthy, and won’t leave you dry after a month. This means arranging meetups, exchanging
                    messages, and deciding whether their schedule works for you. Finally, once you've found a renter, you’ll have to decide a method of payment.
                </p>
                <br />
                <p>This is the traditonal and proven way. It can be tedious and time-consuming — but it works.</p>
            </section>

            <section className="post-section">
                <h2>2. The Easier Way</h2>
                <p>If that all sounds like too much hassle, there’s an easier way: rental apps.</p>
                <br />
                <p>Instead of spending hours juggling platforms and messages, you can list your driveway on a dedicated app in under 30 minutes.</p>
                <br />
                <p>
                    The process is fairly simple — enter your address, add a few photos, and connect your preferred payment method. These platform notifies you when a driver is found, and it handles
                    the entire process for you, including scheduling and managing the payments.
                </p>
                <br />
                <p>Parket, SpotHero, Spacer, and Neighbors are all valid choices</p>
                <br />
                <p>
                    Though, unlike other apps that only allow monthly rentals, Parket offers flexibility. You can choose to rent out your driveway hourly, daily, weekly, or monthly, with fine control
                    over your schedule down to the minute. If you’re only available to rent your spot out on specific days or at certain times, you can set your availability exactly how you like it.
                </p>
                <br />
                <p>Parket has a 8% booking fee, while others range from 15% to 20%.</p>
            </section>

            <section className="post-section">
                <h2>3. Quick Interview with Laura</h2>
                <p>
                    Laura Thompson, a waiter living in Queens, NY, didn’t think much about her driveway. It was just there for her to park her car — until she realized she could rent it out while she
                    was at work. She worked long, 12-hour shifts at a restaurant, and her driveway sat empty all day. It seemed like the perfect opportunity to make some extra cash.
                </p>
                <br />
                <p>
                    At first, Laura tried to rent out her space by listing it on Craigslist and Facebook Marketplace. But after months of answering calls, texts, and emails, she couldn't find a driver
                    who fit her schedule.
                </p>
                <br />
                <p>
                    She tried a few parking apps, hoping they would help her out. But most of them only offered monthly rentals, but she didn’t want to lose access to her driveway when she was off
                    work. “Those apps didn’t really work for me,” she said. “I needed more flexibility.”
                </p>
                <br />
                <p>
                    That’s when she came across Parket. Unlike the other platforms, Parket allowed her to list her driveway by the hour. Laura was able to set her schedule to rent out the space only
                    during her shifts. Within a few weeks, Laura managed to find multiple drivers—earning her $192 in her first month.
                </p>
                <br />
                <p>
                    As of today, Laura’s spot earns around $250 a month just from renting her driveway during her shifts — enough to cover her phone bill and a decent part of her utilities. For Laura,
                    the extra income is a small but significant relief.
                </p>
            </section>

            <section className="post-section">
                <h2>4. Consider Your Options</h2>
                <p>Instead of following the traditional way — save time by listing it on a rental service.</p>
            </section>
        </div>
    )
}
